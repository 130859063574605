import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

interface AllValidationErrors {
  controlName: string;
  errorName: string;
  errorValue: any;
}

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent extends DestroyableComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() formRef: ElementRef;
  @Input() messages: any;

  public errors: AllValidationErrors[];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private translateService: TranslateService,
  ) {
    super();
    this.errors = [];
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        this.errors = [];
        this.calculateErrors(this.form);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.errors = [];
        this.calculateErrors(this.form);
      }
    });

    this.calculateErrors(this.form);
  }

  public get formState(): any {
    return {
      valid: this.form.valid,
      pristine: this.form.pristine,
      dirty: this.form.dirty,
      touched: this.form.touched,
    };
  }

  calculateErrors(form: UntypedFormGroup | UntypedFormArray) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.errors = this.errors.concat(this.calculateErrors(control));
        return;
      }

      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          this.errors.push({
            controlName: field,
            errorName: keyError,
            errorValue: controlErrors[keyError],
          });
        });
      }
    });

    const formErrors: ValidationErrors = form.errors;
    if (formErrors !== null) {
      Object.keys(formErrors).forEach((keyError) => {
        this.errors.push({
          controlName: 'form',
          errorName: keyError,
          errorValue: formErrors[keyError],
        });
      });
    }

    // This removes duplicates
    this.errors = this.errors.filter(
      (error, index, self) =>
        self.findIndex((t) => t.controlName === error.controlName && t.errorName === error.errorName) === index,
    );
    return this.errors;
  }

  getErrorMessage(error) {
    switch (error.errorName) {
      case 'required':
        return this.translateService.instant('mustFill') + ' ' + this.messages[error.controlName];
      default:
        return 'unknown error ' + error.errorName;
    }
  }
}
