<div class="form-errors"
     [class.valid]="form.valid"
     [class.invalid]="!form.valid">
  <div class="state">
    <div>{{ form.status }}</div>
    <div [class.strong]="form.dirty">
      {{ form.pristine ? 'pristine' : '' }}
      {{ form.dirty ? 'dirty' : '' }}
    </div>
    <div [class.strong]="form.touched">
      {{ form.touched ? 'touched' : 'untouched' }}
    </div>
    <div [class.strong]="form.valid">
      {{ form.valid ? 'valid' : 'invalid' }}
    </div>
  </div>

  <pre>{{ errors | json }}</pre>
</div>
